import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Recommendations, Gpus} from './recommendations';
import {root} from 'rxjs/internal-compatibility';
import {DomSanitizer} from '@angular/platform-browser';
import {getGPUTier} from 'detect-gpu';
import * as THREE from 'three';
import {GlobalsService} from './globals.service';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})




export class AppComponent implements OnInit {
  title = 'Gato';
  public landingPage = true;
  private inventory: any;
  public gpuScore: bigint;
  public gpuDescription: string;
  public gpuRecommendations: Array<Recommendations> = [];
  public storageRecommendations: Array<Recommendations> = [];
  public memoryRecommendations: Array<Recommendations> = [];
  windowWidth: string;
  showSplash = true;
private errorMessage: string;
private spinLock = false;
  public storage = {about: 'Loading...', upgrades: []};
  public memory: any;
  tiles: Tile[] = [
    {text: 'One', cols: 3, rows: 1, color: 'lightblue'},
    {text: 'Two', cols: 1, rows: 2, color: 'lightgreen'},
    {text: 'Three', cols: 1, rows: 1, color: 'lightpink'},
    {text: 'Four', cols: 2, rows: 1, color: '#DDBDF1'},
  ];

  constructor(
    private route: ActivatedRoute, private http: HttpClient, public dom: DomSanitizer, public r: Router, public g: GlobalsService) {
  }

  /**
   * retrieve the name of the default videocard of the system
   * using webgl.
   *
   * @see https://stackoverflow.com/questions/49267764/how-to-get-the-video-card-driver-name-using-javascript-browser-side
   * @returns {Object}
   */
  getVideoCardInfo(): string {
    const goat = getGPUTier();
    goat.then( (data) => { console.log(data); }).catch( (error) => console.log(error)).finally( () => console.log('done'));
    const gl = document.createElement('canvas').getContext('webgl');
    if (!gl) {
      return '';
    }
    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
    if(debugInfo){
      console.log( {
        vendor: gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL),
        renderer:  gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL),
      });
      return gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
    }
    return 'no WEBGL_debug_renderer_info';
    }

  rankGPU( score: bigint): string {
    if (score < 4000){
      return 'Your card is suitable for basic use (text, web surfing).  It might have difficulty on media rich web sites.  You probably want to upgrade it.';
    }
    if (score < 8000){
      return 'Your card is suitable for most every day use.  It will not be able to play the latest games, handle VR, or mine cryptocurrency, but casual games are fine.';
    }
    if (score < 10000){
      return 'Your card can handle most video games and some VR - it isn\'t the greatest available, but it\'s pretty good.  Upgrades might be hard to find.';
    }
    return 'Your card is one of the faster available - you might have trouble sourcing a better one.';
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    // splash
    setTimeout(() => {
      console.log('hiding splash');
      this.windowWidth = '-' + window.innerWidth + 'px';
      setTimeout(() => {
        this.showSplash = !this.showSplash;
        this.g.splash = false;
      }, 500);
    }, 1000);
    console.log('Init');
    console.log(this.getVideoCardInfo());
    this.route.queryParams.subscribe(params => {
      console.log(params);
      console.log(params.id);
      console.log('router ', this.r.url);
      console.log('windows ', window.document.URL);
      const theUrl = new URL(window.document.URL);
      const findMe = theUrl.searchParams.get('id');
      if (findMe && findMe.length > 0) {
        console.log('using param');
        // disable the landing page
        this.landingPage = false;
        this.g.landingMode = false;
        this.r.navigate(['/results']);

      }else{
        this.landingPage = true;
        this.g.landingMode = true;
        this.r.navigate(['/landing']);
        console.log('No data to parse - show landing');
      }
      // fetch
      this.http.get<any>('/v1/suggest/' + findMe).subscribe({
        next: data => {
          console.log(data);
          if (this.spinLock){ return; }
          this.spinLock = true;
          this.inventory = data;
          this.gpuScore = this.inventory.gpu.threed;
          this.storage = this.inventory.storage;
          this.memory = this.inventory.memory;
          this.gpuDescription = this.rankGPU(this.gpuScore);
          this.inventory.gpu.upgrades.forEach( (record: Gpus) => {
            if (record.threed > this.gpuScore && record.amazonLong && record.amazonLong.length > 1){
              const rank: number = Math.round(record.threed / Number(this.gpuScore));
              const foundItem: Recommendations = {
                name: record.tag,
                about: record.about,
                amazonLong: decodeURIComponent(record.amazonLong),
                score: record.threed,
                amazonShort: record.amazonShort,
                timesBetter: rank,
                neShort: record.neShort,
                ngLong: record.neLong,
              };
              this.gpuRecommendations.push(foundItem);
            }
          }); // end foreach
          // now ck the storage
          this.inventory.storage.upgrades.forEach( (record: Gpus) => {
//            if (record.threed > this.gpuScore && record.amazonLong && record.amazonLong.length > 1){
              const rank: number = Math.round(record.threed / Number(this.gpuScore));
              const foundItem: Recommendations = {
                name: record.tag,
                about: record.about,
                amazonLong: decodeURIComponent(record.amazonLong),
                score: record.threed,
                amazonShort: record.amazonShort,
                timesBetter: rank,
                neShort: record.neShort,
                ngLong: record.neLong,
              };
              this.storageRecommendations.push(foundItem);
//            }
          }); // end foreach

        },
        error: error => {
          this.errorMessage = error.message;
          console.error('There was an error!', error);
        }
      });
    });
  }

}
