import { Component, OnInit } from '@angular/core';
import {GlobalsService} from '../globals.service';

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.css']
})
export class BarComponent implements OnInit {

  constructor(public g: GlobalsService) { }

  ngOnInit(): void {

  }

  how(): void {
    console.log('cat');
  }
}
