import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {getGPUTier} from 'detect-gpu';
import { BenchmarkComponent } from './benchmark/benchmark.component';
import { SavedComponent } from './saved/saved.component';
import { LandingComponent } from './landing/landing.component';
import { BarComponent } from './bar/bar.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ResultsComponent } from './results/results.component';
import {CookieService} from 'ngx-cookie-service';
import { WebglresultsComponent } from './webglresults/webglresults.component';
@NgModule({
  declarations: [
    AppComponent,
    BenchmarkComponent,
    SavedComponent,
    LandingComponent,
    BarComponent,
    PrivacyComponent,
    ResultsComponent,
    WebglresultsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatButtonModule,
    MatTableModule,
    MatCardModule,
    MatGridListModule,
    MatTableModule,
    MatIconModule,
    MatToolbarModule,
    NgbModule,
    MatCardModule,
    MatListModule,

  ],
  providers: [    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
