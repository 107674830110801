<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <img
    width="40"
    style="margin-left: 5%;"
    alt="Angular Logo"
    src="/assets/black_cat.svg"
  />
  <a class="navbar-brand" href="#">Gato Robato</a>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="g.landing">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a (click)="how()" routerLink="/landing" routerLinkActive="active" class="nav-link" href="#">How it works </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" routerLink="/benchmark" routerLinkActive="active" class="nav-link" >Quick Benchmark</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/privacy" routerLinkActive="active" class="nav-link" href="#">Privacy Policy</a>
      </li>
    </ul>
  </div>
</nav>
