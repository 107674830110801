<!-- Toolbar -->
<div class="toolbar" role="banner">
  <img
    width="40"
    alt="Angular Logo"
    src="/assets/black_cat.svg"
  />
  <span style="font-size: xx-large">Gato Robato</span>
    <div class="spacer"></div>
  <span style="font-size: xx-large; margin-right: 5%;">
    <a href="/?id=">
          <mat-icon aria-hidden="false" aria-label="Example home icon" style="font-size: xx-large; color: #eeeeee">home</mat-icon>
    </a>
  </span>
</div>
<app-bar></app-bar>

<div
  class="app-splash-screen"
  [ngStyle]="{ left: windowWidth }"
  *ngIf="g.splash"
>
  <div class="app-splash-inner">
    <div class="app-logo"></div>
    <div class="app-label">The Gato is preparing recommendations</div>
    <div class="app-loader"></div>
  </div>
</div>
<div class="content" role="main" style="width: 95%; margin-left: 5%; margin-top: 10%;" *ngIf="landingPage">
</div>
<div class="content" role="main" style="width: 95%; margin-left: 5%" *ngIf="!landingPage">
  <mat-tab-group animationDuration="2000ms">
    <mat-tab label="GPU/Video Card">
      <mat-grid-list cols="1" rowHeight="100px">
        <mat-grid-tile
          [colspan]="1"
          [rowspan]="1">
          <p style="align-content: center; font-size: xx-large">Your estimated GPU score is {{ gpuScore }}</p>



        </mat-grid-tile>
      </mat-grid-list>

      <br/>
      <p style="align-content: center; font-size: x-large;margin-left: 5%;margin-right: 5%; ">{{ gpuDescription }}</p>
      <ngb-carousel interval="30000" pauseOnHover="true" >
        <ng-template ngbSlide *ngFor="let gpu of gpuRecommendations">
          <mat-card class="example-card">
            <mat-card-header>
              <mat-card-title><h1>{{ gpu.name }}</h1></mat-card-title>
              <mat-card-subtitle>Around {{ gpu.timesBetter }}x better</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content style="align-content: center;">
              <mat-grid-list cols="5" rowHeight="120px">
                <mat-grid-tile
                  [colspan]="3"
                  [rowspan]="3"
                  [style.background]="lightblue">
                  <div style="margin-left: 5%;margin-right: 5%;">{{ gpu.about }}</div>
                </mat-grid-tile>
                <mat-grid-tile
                  [colspan]="1"
                  [rowspan]="3"
                  [style.background]="lightblue">
                  <div [innerHTML]="this.dom.bypassSecurityTrustHtml(gpu.amazonLong)"> </div>
                </mat-grid-tile>
                <mat-grid-tile
                  [colspan]="1"
                  [rowspan]="3"
                  [style.background]="lightblue">

                </mat-grid-tile>
              </mat-grid-list>

            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
          </mat-card>
        </ng-template>
      </ngb-carousel>

    </mat-tab>
    <mat-tab label="Memory">Memory

      <p style="align-content: center">Gato can't find any upgrades for your system.  Sorry :(</p>
      <br/>

<!--
      <ngb-carousel >
        <ng-template ngbSlide *ngFor="let gpu of gpuRecommendations">
          <mat-card class="example-card" style="background-color: white; align-content: center;">
            <mat-card-header>
              <mat-card-title><h1>{{ gpu.name }}</h1></mat-card-title>
              <mat-card-subtitle>Around {{ gpu.timesBetter }}x better</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div [innerHTML]="this.dom.bypassSecurityTrustHtml(gpu.amazonLong)"> </div>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
          </mat-card>
        </ng-template>
      </ngb-carousel>
-->

    </mat-tab>
    <mat-tab label="Storage">
      <br/>
      {{ storage.about }}
      <ngb-carousel interval="30000" pauseOnHover="true" >
        <ng-template ngbSlide *ngFor="let gpu of storageRecommendations">
          <mat-card class="example-card" style="background-color: lavenderblush; align-content: center;">
            <mat-card-header>
              <mat-card-title><h1>{{ gpu.name }}</h1></mat-card-title>
            </mat-card-header>
            <mat-card-content style="align-content: center;">
              <mat-grid-list cols="5" rowHeight="120px">
                <mat-grid-tile
                  [colspan]="3"
                  [rowspan]="3"
                  [style.background]="lightblue">
                  <div style="margin-left: 5%;margin-right: 5%;">{{ gpu.about }}</div>
                </mat-grid-tile>
                <mat-grid-tile
                  [colspan]="1"
                  [rowspan]="3"
                  [style.background]="lightblue">
                  <div [innerHTML]="this.dom.bypassSecurityTrustHtml(gpu.amazonLong)"> </div>
                </mat-grid-tile>
                <mat-grid-tile
                  [colspan]="1"
                  [rowspan]="3"
                  [style.background]="lightblue">

                </mat-grid-tile>
              </mat-grid-list>

            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
          </mat-card>
        </ng-template>
      </ngb-carousel>

    </mat-tab>

  </mat-tab-group>
  <!-- Highlight Card -->




  <!-- Links -->
  <div class="card-container">

  </div>

  <!-- Footer -->
  <footer>

  </footer>


</div>


<router-outlet></router-outlet>
