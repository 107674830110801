<mat-card class="example-card">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title>Fast Test</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>
  <div
    class="app-splash-screen"
    [ngStyle]="{ left: windowWidth }"
    *ngIf="g.splash"
  >
    <div class="app-splash-inner">
      <div class="app-logo"></div>
      <div class="app-label">The Gato is running the test in another window</div>
      <div class="app-loader"></div>
    </div>
  </div>

  <mat-card-content style="margin-left: 5%; margin-right: 5%;">
    <div>
      <p class="readable">
        This is not as accurate as the hardware scan - but the Gato can guess at your hardware based
        on the speed of performing a WebGL animation.   Note that if your system or browser are old
        this will fail.

      </p><br/>
      <p class="readable">

      </p>
      <p class="readable">
        <div *ngIf="testRunning">
         {{ userSpew }}
         </div>
      <div *ngIf="!testRunning">
        <a (click)="startTest()" target="_blank"> <button class="btn btn-primary">Start</button> </a>
      </div>

      <p class="readable">
    </div>
  </mat-card-content>
  <mat-card-actions>
  </mat-card-actions>
</mat-card>





