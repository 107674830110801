<mat-card class="example-card">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title>How it works</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>

  <mat-card-content style="margin-left: 5%; margin-right: 5%;">
    <div>
      <p class="readable">
        Sorting out hardware is hard.
      </p><br/>
      <p class="readable">
        Gato is a collection of AI rules and info to help you know how good your system is, and if you need
        an upgrade depending on what you do.   If you are an author, you don't need the best video card.
        If you are playing the latest VR game, well - you do.   Gato will customize the results.  We log what
        we send to the server, and never link the hardware info with your email, identity, or IP address.
        The Gato code is available on GitHub for review, or you can build it yourself.
      </p>
      <p class="readable">
        Download the signed binary for your OS, run it, and get a customized recommendations today!  Don't feel
        comfortable running a binary?   Use the <a href="#" (click)="benchmark()">WebGL benchmark</a>.
      <p class="readable">
      <table class="table table-hover">
      <thead class="thead-dark">
        <tr>
          <th scope="col">OS</th>
          <th scope="col">Type</th>
          <th scope="col">Link</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Linux 64</td>
          <td>ELF64</td>
          <td><a href="https://gatorobato.com/files/gato_linux_amd64_210619">gato_linux_amd64_210619</a></td>
        </tr>
        <tr>
          <td>Linux 64</td>
          <td>ELF64</td>
          <td><a href="https://gatorobato.com/files/gato_linux_amd64_210619.sig">gato_linux_amd64_210619.sig</a></td>
        </tr>
        <tr>
          <td>Linux ARM64</td>
          <td>ARM64</td>
          <td><a href="https://gatorobato.com/files/gato_linux_arm64_210619">gato_linux_arm64_210619</a></td>
        </tr>
        <tr>
          <td>Linux ARM64</td>
          <td>ARM64</td>
          <td><a href="https://gatorobato.com/files/gato_linux_arm64_210619.sig">gato_linux_arm64_210619.sig</a></td>
        </tr>
        <tr>
          <td>Windows AMD64</td>
          <td>AMD64</td>
          <td><a href="https://gatorobato.com/files/gato_win64_210619.exe">gato_win64_210619.exe</a></td>
        </tr>
        <tr>
          <td>Linux ARM64</td>
          <td>ARM64</td>
          <td><a href="https://gatorobato.com/files/gato_win64_210619.exe">gato_win64_210619.exe.sig</a></td>
        </tr>
      </tbody>
      </table>

    </div>
  </mat-card-content>
  <mat-card-actions>
  </mat-card-actions>
</mat-card>



