import { Component, OnInit } from '@angular/core';
import {Gpus, Recommendations} from '../recommendations';
import {Tile} from '../app.component';
import {getGPUTier} from 'detect-gpu';
import {CookieService} from 'ngx-cookie-service';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {GlobalsService} from '../globals.service';

@Component({
  selector: 'app-webglresults',
  templateUrl: './webglresults.component.html',
  styleUrls: ['./webglresults.component.css']
})
export class WebglresultsComponent implements OnInit {
  title = 'Gato';
  public landingPage = true;
  private inventory: any;
  public gpuScore: number;
  public gpuDescription: string;
  public gpuRecommendations: Array<Recommendations> = [];
  public storage = {about: 'Loading...', upgrades: []};
  public memory: any;
  tiles: Tile[] = [
    {text: 'One', cols: 3, rows: 1, color: 'lightblue'},
    {text: 'Two', cols: 1, rows: 2, color: 'lightgreen'},
    {text: 'Three', cols: 1, rows: 1, color: 'lightpink'},
    {text: 'Four', cols: 2, rows: 1, color: '#DDBDF1'},
  ];
  constructor( public c: CookieService, private http: HttpClient, public dom: DomSanitizer, public g: GlobalsService) { }


  ngOnInit(): void {
    // translate gatos into approximate scores used by the commercial apps
    this.gpuScore = Number(this.c.get('gato'));
    if( this.gpuScore === 0 ){
      // no data = meh card, i.e. - can't run at all.
      this.gpuScore = 6000;
    }
    console.log('goat');
    console.log(this.c.get('gato'));
    this.http.get<any>('/v1/websuggest/' + this.gpuScore).subscribe({
      next: data => {
        console.log(data);
        this.inventory = data;
        this.inventory.gpu.upgrades.forEach((record: Gpus) => {
          if (record.threed > this.gpuScore && record.amazonLong && record.amazonLong.length > 1){
            const rank: number = Math.round(record.threed / Number(this.gpuScore));
            const foundItem: Recommendations = {
              name: record.tag,
              about: record.about,
              amazonLong: decodeURIComponent(record.amazonLong),
              score: record.threed,
              amazonShort: record.amazonShort,
              timesBetter: rank,
              neShort: record.neShort,
              ngLong: record.neLong,
            };
            console.log(foundItem);
            this.gpuRecommendations.push(foundItem);
          }
        }); // end foreach

      }
    });

      }
  /**
   * retrieve the name of the default videocard of the system
   * using webgl.
   *
   * @see https://stackoverflow.com/questions/49267764/how-to-get-the-video-card-driver-name-using-javascript-browser-side
   * @returns {Object}
   */
  getVideoCardInfo(): string {
    const goat = getGPUTier();
    goat.then( (data) => { console.log(data); }).catch( (error) => console.log(error)).finally( () => console.log('done'));
    const gl = document.createElement('canvas').getContext('webgl');
    if (!gl) {
      return '';
    }
    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
    if(debugInfo){
      console.log( {
        vendor: gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL),
        renderer:  gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL),
      });
      return gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
    }
    return 'no WEBGL_debug_renderer_info';
  }

  rankGPU( score: bigint): string {
    if (score < 4000){
      return 'Your card is suitable for basic use (text, web surfing).  It might have difficulty on media rich web sites.  You probably want to upgrade it.';
    }
    if (score < 8000){
      return 'Your card is suitable for most every day use.  It will not be able to play the latest games, handle VR, or mine cryptocurrency, but casual games are fine.';
    }
    if (score < 10000){
      return 'Your card can handle most video games and some VR - it isn\'t the greatest available, but it\'s pretty good.  Upgrades might be hard to find.';
    }
    return 'Your card is one of the faster available - you might have trouble sourcing a better one.';
  }
}
