import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  public landing = true;
  public splash = true;
  get landingMode(): boolean {
    return  this.landing;
  }
  set landingMode(value: boolean) {
    this.landing = value;
  }
  constructor() { }
}
