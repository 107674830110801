import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as THREE from 'three'
import {from} from 'rxjs';
import { Vector3 } from 'three';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {GlobalsService} from '../globals.service';
@Component({
  selector: 'app-benchmark',
  templateUrl: './benchmark.component.html',
  styleUrls: ['./benchmark.component.css']
})
export class BenchmarkComponent implements OnInit {
  @ViewChild('my_Canvas') private canvas: ElementRef<HTMLCanvasElement>;
  private gl: CanvasRenderingContext2D | ImageBitmapRenderingContext | WebGLRenderingContext | WebGL2RenderingContext;
  testRunning = false;
  userSpew = 'Test is running - please wait';
  private windowWidth: string;
  constructor( public c: CookieService, public r: Router, public g: GlobalsService) { }

  runTest(): void{
    const vertices = [
      -1, -1, -1, 1, -1, -1, 1, 1, -1, -1, 1, -1,
      -1, -1, 1, 1, -1, 1, 1, 1, 1, -1, 1, 1,
      -1, -1, -1, -1, 1, -1, -1, 1, 1, -1, -1, 1,
      1, -1, -1, 1, 1, -1, 1, 1, 1, 1, -1, 1,
      -1, -1, -1, -1, -1, 1, 1, -1, 1, 1, -1, -1,
      -1, 1, -1, -1, 1, 1, 1, 1, 1, 1, 1, -1,
    ];
    const colors = [
      5, 3, 7, 5, 3, 7, 5, 3, 7, 5, 3, 7,
      1, 1, 3, 1, 1, 3, 1, 1, 3, 1, 1, 3,
      0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1,
      1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0,
      1, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1, 0,
      0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0
    ];
    const indices = [
      0, 1, 2, 0, 2, 3, 4, 5, 6, 4, 6, 7,
      8, 9, 10, 8, 10, 11, 12, 13, 14, 12, 14, 15,
      16, 17, 18, 16, 18, 19, 20, 21, 22, 20, 22, 23
    ];
    // tslint:disable-next-line:variable-name
   // let vertex_buffer = this.gl.canvas.getContext.
  }
  ngOnInit(): void {
    this.windowWidth = '-' + window.innerWidth + 'px';

  }

  startTest(): void {
    // wipe the cookie - some browsers kill the messages so we go old skool
    document.cookie = 'gato=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    this.testRunning = true;
    this.g.splash = true;
    const popup = window.open('assets/benchmark.html');
    setTimeout( (x) => {
      console.log('timeout');
      this.g.splash = false;
      console.log(this.testRunning);
      console.log(this.c.get('gato'));
      this.testRunning = false;
      this.userSpew = 'Score is ' + this.c.get('gato');
      this.r.navigate(['/webglresults']);
      return;
    }, 70000);

  }

}
