<mat-card class="example-card">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title>How it works</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>

  <mat-card-content style="margin-left: 5%; margin-right: 5%;">
    <div>
      <p class="readable">
        Gato does not track your IP address, identity, or any other information that could identify you.  In conformance
        with the GDPR and other privacy laws, your system configuration is only identified by a random code and is
        discarded after a period of around six months.  If you wish to have it deleted immediately, send the URL
        that appears on the result to gdpr AT gatorobato.com.
      </p>
      <p class="readable">
        The software downloaded will output exactly what is posted to the bot in gato.txt in the run directory.
      </p>
    </div>
  </mat-card-content>
  <mat-card-actions>
  </mat-card-actions>
</mat-card>



