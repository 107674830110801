<div class="content" role="main" style="width: 95%; margin-left: 5%">
  <mat-tab-group animationDuration="2000ms">
    <mat-tab label="GPU/Video Card">
      <mat-grid-list cols="1" rowHeight="100px">
        <mat-grid-tile
          [colspan]="1"
          [rowspan]="1">
          <p style="align-content: center; font-size: xx-large">Your estimated GPU score is {{ gpuScore }}</p>
        </mat-grid-tile>
      </mat-grid-list>
      <br/>
      <p style="align-content: center; font-size: x-large;margin-left: 5%;margin-right: 5%; ">{{ gpuDescription }}</p>
      <ngb-carousel interval="30000" pauseOnHover="true" >
        <ng-template ngbSlide *ngFor="let gpu of gpuRecommendations">
          <mat-card class="example-card">
            <mat-card-header>
              <mat-card-title><h1>{{ gpu.name }}</h1></mat-card-title>
              <mat-card-subtitle>Around {{ gpu.timesBetter }}x better</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content style="align-content: center;">
              <mat-grid-list cols="5" rowHeight="120px">
                <mat-grid-tile
                  [colspan]="3"
                  [rowspan]="3"
                  [style.background]="lightblue">
                  <div style="margin-left: 5%;margin-right: 5%;">{{ gpu.about }}</div>
                </mat-grid-tile>
                <mat-grid-tile
                  [colspan]="1"
                  [rowspan]="3"
                  [style.background]="lightblue">
                  <div [innerHTML]="this.dom.bypassSecurityTrustHtml(gpu.amazonLong)"> </div>
                </mat-grid-tile>
                <mat-grid-tile
                  [colspan]="1"
                  [rowspan]="3"
                  [style.background]="lightblue">

                </mat-grid-tile>
              </mat-grid-list>

            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
          </mat-card>
        </ng-template>
      </ngb-carousel>
    </mat-tab>
  </mat-tab-group>
  <!-- Highlight Card -->




  <!-- Links -->
  <div class="card-container">

  </div>

  <!-- Footer -->
  <footer>

  </footer>


</div>
