import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  constructor( public r: Router) { }

  ngOnInit(): void {
  }

  benchmark(): void {
    this.r.navigate(['/benchmark']);
  }
}
