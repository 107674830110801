import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppComponent} from './app.component';
import {BenchmarkComponent} from './benchmark/benchmark.component';
import {SavedComponent} from './saved/saved.component';
import {LandingComponent} from './landing/landing.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {ResultsComponent} from './results/results.component';
import {WebglresultsComponent} from './webglresults/webglresults.component';

const routes: Routes = [
  {path: '*/', component: AppComponent},
  {path: 'benchmark', component: BenchmarkComponent},
  {path: 'saved', component: SavedComponent},
  {path: 'privacy', component: PrivacyComponent},
  {path: 'results/:id', component: ResultsComponent},
  {path: 'landing', component: LandingComponent},
  {path: 'webglresults', component: WebglresultsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
